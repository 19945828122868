import axios from "axios";
import jwt_decode from "jwt-decode";
import EventBus from "eventing-bus";
import { all, takeEvery, takeLatest, call, put } from "redux-saga/effects";

import {
  setLoginData,
  setTaxData,
  setKYC,
  setDonations,
  toggleDeployModal,
  toggleStatusModal,
  setKycStatus,
} from "../actions/Auth";

/*========== LOGIN FUNCTIONS =============*/

export function* login({ payload, history }) {
  const { error, response } = yield call(postCall, {
    path: "/users/auth",
    payload,
  });
  if (error) {
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    const decoded = jwt_decode(response["data"]["body"]["token"]);
    if (decoded["role"] !== "admin") {
      EventBus.publish("error", "Can't login through User account ");
      return;
    }
    yield put(setLoginData(response["data"]["body"]));
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("success", response["data"]["message"]);
    setTimeout(() => history.push("/home"), 1000);
  }
}

/*========== PRE-REGISTER FUNCTIONS =============*/

export function* getTransactionData() {
  const { error, response } = yield call(getCall, "/viewTaxTxs");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) {
    yield put(setTaxData(response["data"]["body"]));
  }
}

/*========== APPROVE KYC FUNCTIONS =============*/

export function* getKYC() {
  const { error, response } = yield call(getCall, "/getPendingKyc");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setKYC(response["data"]["body"]));
}

export function* updateKYC({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/kycUpdate",
    payload,
  });
  if (error) {
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(toggleStatusModal(false));
    yield put({ type: "GET_KYC" });
    EventBus.publish("success", response["data"]["message"]);
  }
}

/*========== DEPLOY CONTRACT FUNCTIONS ============= */

function* deployDonations({payload}) {
  console.log("********* payload donation", payload);
  const { error, response } = yield call(postCall, {
    path: "/deployDonations",
    payload,
  });
  if (error) {
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(toggleDeployModal(false));
    yield put({
      type: "GET_DONATIONS",
      payload: {
        walletAddress: payload["walletAddress"],
      },
    });
  }
}

export function deployDonation({payload}) {
  console.log("********* payload", payload);
  // deployDonations(payload);
  const { error, response } = postCall({
    path: "/deployDonations",
    payload,
  });
  console.log("********* response", response);

  if (error) {
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    put(toggleDeployModal(false));
    put({
      type: "GET_DONATIONS",
      payload: {
        walletAddress: payload["walletAddress"],
      },
    });
  }
}

export function* getDonations({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/getDonations",
    payload,
  });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response)
    yield put(setDonations(response["data"]["body"][0]["donationsContract"]));
}

export function* getKycStatus({ payload }) {
  const { error, response } = yield call(postCall, { path: '/getKycStatus', payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setKycStatus(response['data']['body'][0]['kycStatus']));
  }
};

// function* actionWatcher() {
//   yield takeEvery('LOGIN', login);
//   yield takeEvery('GET_KYC', getKYC);
//   yield takeEvery('UPDATE_KYC', updateKYC);
//   yield takeEvery('GET_DONATIONS', getDonations);
//   yield takeEvery('GET_TAX_DATA', getTransactionData);
//   yield takeEvery('DEPLOY_DONATIONS', deployDonations);
// };

// export default function* rootSaga() {
//   yield all([actionWatcher()]);
// };

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}
