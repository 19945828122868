import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from "react";
import * as ethUtil from 'ethereumjs-util';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { web3 } from "../../store/web3";
import logo from '../../assets/img/logoBlue.webp';
import XDC from '../../assets/img/xdc.svg';
import { login, getKycStatus } from "../../store/actions/Auth";
import { networkId, message } from "../../store/config";
import { Approve, Token } from '../../store/contract/index';


import './index.css';
import { Fragment } from 'react';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      netId: '',
      address: '',
    };
  };

  async componentDidMount() {
    web3.eth.net.getId((err, netId) => {
      this.setState({ netId })
    });
    if (!window.ethereum) {
      EventBus.publish("error", "Please install metamask");
      return;
    };
    this.checkAddresses();

  };

  // componentWillReceiveProps() {
  //   this.checkAddresses();
  // }

  checkAddresses = async () => {
    let address = (await web3.currentProvider.enable())[0];
    this.setState({ address });
    this.props.getKycStatus({ publicAddress: address });
  };


  handleLogin = async () => {
    let { kycStatus } = this.props;

    if (!window.ethereum) {
      EventBus.publish("error", "Please install metamask");
      return;
    };

    let { address } = this.state;
    if (!address) return EventBus.publish("error", "Wallet Address Not Found");

    const nonce = Math.floor(Math.random() * 10000000000);
    const signature = await web3.eth.personal.sign(web3.utils.utf8ToHex(`User Admin Donations ${nonce}`), address)

    const msg = new Buffer(`User Admin Donations ${nonce}`);
    const msgBuffer = ethUtil.toBuffer(msg);
    const msgHash = ethUtil.hashPersonalMessage(msgBuffer);
    const signatureBuffer = ethUtil.toBuffer(signature);
    const signatureParams = ethUtil.fromRpcSig(signatureBuffer);
    const publicKey = ethUtil.ecrecover(
      msgHash,
      signatureParams.v,
      signatureParams.r,
      signatureParams.s
    );
    const addressBuffer = ethUtil.publicToAddress(publicKey);
    const adminAddress = ethUtil.bufferToHex(addressBuffer);
    let whitelist = await (Approve.methods.isWhitelist(adminAddress).call());
    if (whitelist == true) {
      console.log("********* whitelist", whitelist);
      this.props.login(address);
      if (kycStatus == true) {
        console.log("********* kycStatus", kycStatus);
        setTimeout(() => this.props.history.push('/home'), 1000);
      }
      else { EventBus.publish("error", "KYC still pending!"); }
    }
    else EventBus.publish("error", "Please login through whitelisted address");
    
    // if (address) {
    //   this.props.login(address);
    //   setTimeout(() => this.props.history.push('/home'), 1000);
    // }
    // else EventBus.publish("error", "Please login through super admin address");
  };


  render() {
    let { netId, } = this.state;
    return (
      <div className="login-page">
          <div className="container login-area">
            <div className="login-form">
              <h4 className="login-title"><img src={logo} alt="logo" className='logoLogin' />Blockfund</h4>
              <h1>Welcome Founders</h1>
           <p className='text-white'>To enter the platform and manage your causes and donations, please login using your XDC Pay wallet.</p>
              {(netId != networkId)
                ? <div className="login-text pt-4"><p>{message}</p></div>
                : <Fragment>
                  <ValidatorForm className="validator-form " onSubmit={this.handleLogin}>
                    <Button type="Submit" variant="contained" className='text-white login-btn'>
                      Login with XDCPay Wallet
                      <img src={XDC} alt="xdc" className='xdc' />
                    </Button>
                  </ValidatorForm>
                </Fragment>
              }
            </div>
          </div>
        <div className='bgLoginPg'></div>
      </div >
    );
  }
}

const mapDispatchToProps = {
  login,
  getKycStatus
};

const mapStateToProps = ({ Auth }) => {
  let { kycStatus } = Auth
  return { kycStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);