import EventBus from "eventing-bus";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Logo from "../../assets/img/logoBlue.webp";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
} from "reactstrap";

import "./index.css";
import { logout, setAddress } from "../../store/actions/Auth";
import { web3 } from "../../store/web3";
import { Token } from "../../store/contract/index";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
      role: localStorage.getItem("role"),
    };
  }
  async componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    // let address = (await web3.currentProvider.enable())[0];
    // this.props.setAddress(address)
    // let balanceOf = await Token.methods.balanceOf(address).call();
    // let balance = web3.utils.fromWei(balanceOf.toString(), 'ether')
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent",
      });
    } else {
      this.setState({
        color: "bg-white",
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch,
    });
  };

  // logout = () => {
  //   this.props.logout();
  //   this.props.history.push("/");
  // };

  copied = () => EventBus.publish("success", "Address is Copied");

  render() {
    let { role } = this.state;
    let { address, balance } = this.props;
    return (
      <div className="navBarTop px-2">
        <Navbar
          className={classNames("navbar-absolute", this.state.color)}
          expand="lg"
        >
          <div className="navbar-wrapper">
            {/* <div
              className={classNames("navbar-toggle d-inline", {
                toggled: this.props.sidebarOpened,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={this.props.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div> */}
            <NavbarBrand className="logoHeader" href="#" target="_blank">
              <img alt="..." src={Logo} />
              BLOCKFUND
            </NavbarBrand>
          </div>
          <div navbar isOpen={this.state.collapseOpen}>
            <Nav className="ml-auto" navbar>
              <div className="photo">
                <div className='detailsAdmin'>
                  {/* <h4>SuperAdmin</h4> */}
                  <h4>Live Session 🔴</h4>
                </div>
                {/* <img alt="..." src={Logo} /> */}
              </div>
              {/* <b className=" d-none d-lg-block d-xl-block" />
              <p className="d-lg-none">Logout</p>
              <li className="separator d-lg-none" /> */}
            </Nav>
          </div>
        </Navbar>
        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
        >
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = { logout, setAddress };

const mapStateToProps = ({ Auth }) => {
  let { role, balance, address } = Auth;
  return { role, balance, address };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
