import { all, takeEvery } from 'redux-saga/effects';
import { getKYC, login, updateKYC, getDonations, getKycStatus, getTransactionData } from './Auth';

export default function* rootSaga() {
  yield all([
    yield takeEvery('LOGIN', login),
    // yield takeEvery('DEPLOY_DONATIONS', deployDonations),
    yield takeEvery('GET_TAX_DATA', getTransactionData),
    yield takeEvery('GET_DONATIONS', getDonations),
    yield takeEvery('GET_KYC', getKYC),
    yield takeEvery('UPDATE_KYC', updateKYC),
    yield takeEvery('GET_KYC_STATUS', getKycStatus),
  ]);
}
