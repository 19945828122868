
import Deploy from "./views/Deploy/index.js";
import TXDetails from "./views/TXDetails/index.js"
import Deploy1155 from "./views/Deploy1155/index.js";
import ApproveKYC from "./views/ApproveKYC/index.js";
import DonationsDetail from "./views/DonationsDetail/index.js"
import UpdateDonationsDetail from "./views/UpdateDonationsDetail/index.js"



var routes = [
  {
    layout: "/home",
    path: "/",
    component: Deploy1155,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/DeployERC1155",
    name: "ERC-155 Token",
    component: Deploy1155,
    discription: "Deploy & Manage ERC-155 Token",
    img: "/images/erc.svg",
    activeImg: "/images/ercWhite.svg",
  },
  {
    layout: "/home",
    path: "/DeployContract",
    name: "Donations",
    component: Deploy,
    discription:"Deploy Donations",
    img: "/images/donation.svg",
    activeImg: "/images/donationWhite.svg",
  },
  {
    layout: "/home",
    path: "/DonationsDetail",
    name: "Add Donations",
    component: DonationsDetail,
    discription:"Manage Donations",
    img: "/images/addonation.svg",
    activeImg:"/images/addonationWhite.svg",
  },
  {
    layout: "/home",
    path: "/UpdateDonationsDetail",
    name: "Update Donations",
    component: UpdateDonationsDetail,
    discription:"Manage Donations",
    img: "/images/donationData.svg",
    activeImg: "/images/donationDataWhite.svg",
  },
  {
    layout: "/home",
    path: "/TXDetails",
    name: "Donations Data",
    component: TXDetails,
    discription:"info about Donations",
    img: "/images/donationData.svg",
    activeImg: "/images/donationDataWhite.svg",
  },
];

export default routes;