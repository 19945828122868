import { TextValidator } from "react-material-ui-form-validator";
import React from "react";
export default function InputField(props) {
  return (
    <div className="edit-add-field pos-relative">
      <TextValidator
        fullWidth
        type={props.type}
        name={props.name}
        value={props.value}
        variant="filled"
        id="standard-full-width"
        className="form-input-field"
        onChange={props.onChange}
        helperText={props.helperText}
        select={props.select}
      >
        {props.children}
      </TextValidator>
      <div className="labelReqHeadTop">
        <h4>{props.label}</h4>
        {props.required && <h4>*REQUIRED</h4>}
      </div>
    </div>
  );
}
