import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { web3 } from "../../store/web3";
import React, { Component } from "react";
import Switch from "@mui/material/Switch";
import { Button } from "@material-ui/core";
import { validate } from "wallet-address-validator";
import { DonactionsAbi } from "../../store/contract";
import { setLoader, getDonations } from "../../store/actions/Auth.js";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../components/common/InputField";

import "./index.css";

class DonationsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startAmount: 0,
      endAmount: 1,
      type: "",
      address: "",
      tokenId: 1,
      addressArray: [],
      amountStartArray: [],
      amountEndArray: [],
      typeArray: [],
      tokenIdArray: [],
      isPercentage: false,

      donationTypes: [],
    };
  }

  async componentDidMount() {
    await this.getContract();
  }

  async componentWillReceiveProps({ deployedDonations }) {
    if (deployedDonations.length > 0) {
      let result = await this.dTypes(deployedDonations);
      this.setState({ donationTypes: result });
    }
  }

  dTypes = (deployedDonations) => {
    return new Promise(async (resolve, reject) => {
      let donationContract = new web3.eth.Contract(
        DonactionsAbi,
        deployedDonations
      );
      let types = await donationContract.methods.getDonationTypes().call();
      resolve(types);
    });
  };

  async getContract() {
    let walletAddress = (await web3.currentProvider.enable())[0];
    this.props.getDonations({ walletAddress });
  }

  handleEditChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if ([e.target.name] == "startAmount") {
      let { amountStartArray } = this.state;
      let startAmount = e.target.value;
      amountStartArray = startAmount.split(",");
      this.setState({ amountStartArray });
    }
    if ([e.target.name] == "endAmount") {
      let { amountEndArray } = this.state;
      let endAmount = e.target.value;
      amountEndArray = endAmount.split(",");
      this.setState({ amountEndArray });
    }
    if ([e.target.name] == "type") {
      let { typeArray } = this.state;
      let type = e.target.value;
      typeArray = type.split(",");
      this.setState({ typeArray });
    }
    if ([e.target.name] == "tokenId") {
      let { tokenIdArray } = this.state;
      let tokenId = e.target.value;
      tokenIdArray = tokenId.split(",");
      this.setState({ tokenIdArray });
    }
    if ([e.target.name] == "address") {
      let { addressArray } = this.state;
      let address = e.target.value;
      addressArray = address.split(",");
      this.setState({ addressArray });
    }
  };

  togglePercentage = () => {
    this.setState({ isPercentage: !this.state.isPercentage });
  };

  submitTransfer = async () => {
    try {
      let { deployedDonations } = this.props;
      let {
        addressArray,
        amountStartArray,
        amountEndArray,
        typeArray,
        tokenIdArray,
        isPercentage,
        donationTypes,
      } = this.state;

      let startRange = [];
      let endRange = [];
      amountStartArray.map((item) => {
        let value = parseFloat(item);
        value = web3.utils.toWei(value.toString(), "ether");
        startRange.push(value);
      });

      amountEndArray.map((item) => {
        let value = parseFloat(item);
        value = web3.utils.toWei(value.toString(), "ether");
        endRange.push(value);
      });

      console.log("****** startRange", startRange);
      console.log("****** endRange", endRange);

      let publicAddress = (await web3.currentProvider.enable())[0];

      let existingTypes = typeArray.filter((element) =>
        donationTypes.includes(element)
      );
      console.log("****** existingTypes", existingTypes);

      if (deployedDonations.length == 0) {
        EventBus.publish("error", `Please deploy a donation contract first!`);
        return;
      }

      if (addressArray.length == 0) {
        EventBus.publish("error", `Please enter the Benefeciary Addresses`);
        return;
      }

      if (amountStartArray.length == 0) {
        EventBus.publish("error", `Please enter the Donation Start Range`);
        return;
      }

      if (amountEndArray.length == 0) {
        EventBus.publish("error", `Please enter the Donation End Range`);
        return;
      }

      if (typeArray.length == 0) {
        EventBus.publish("error", `Please enter the Donation Types`);
        return;
      }

      if (tokenIdArray.length == 0) {
        EventBus.publish("error", `Please enter the Token Ids`);
        return;
      }

      if (tokenIdArray.length !== amountStartArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (tokenIdArray.length !== amountEndArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (tokenIdArray.length !== typeArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (tokenIdArray.length !== addressArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (typeArray.length !== amountStartArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (typeArray.length !== amountEndArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (typeArray.length !== addressArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (addressArray.length !== amountStartArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (addressArray.length !== amountEndArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      for (let i = 0; i < addressArray.length; i++) {
        if (!validate(addressArray[i], "ETH")) {
          EventBus.publish("error", `No. ${i + 1} is not a valid address`);
          return;
        }
      }

      let donationContract = new web3.eth.Contract(
        DonactionsAbi,
        deployedDonations
      );

      /** Approval **/
      this.props.setLoader({
        message: "Details Submission in Progress...",
        status: true,
      });

      // Submit transaction to the blockchain and wait for it to be mined
      await web3.eth
        .sendTransaction({
          from: publicAddress,
          value: 0,
          to: deployedDonations,
          gas: 2000000,
          data: donationContract.methods
            .defineDonations(
              startRange,
              endRange,
              typeArray,
              addressArray,
              tokenIdArray,
              isPercentage
            )
            .encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          this.setState({
            address: "",
            type: "",
            tokenId: 1,
            startAmount: 0,
            endAmount: 1,
            addressArray: [],
            typeArray: [],
            tokenIdArray: [],
            amountStartArray: [],
            amountEndArray: [],
            isPercentage: false,
          });
          this.props.setLoader({ status: false });
          EventBus.publish("success", `Donation Details Added Successfully!`);
        });
    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Execute Tx`);
    }
  };

  render() {
    let {
      startAmount,
      endAmount,
      type,
      address,
      tokenId,
      isPercentage,
      donationTypes,
    } = this.state;
    console.log("****** donationTypes", donationTypes);

    return (
      <div className="content">
        <div className="main-container mint-new">
          <div className="mint-new-NFT">
            <div className="edit-add">
              <div className="edit-add-title">
                <h1 className="text-dark headTop">Add Donation Details</h1>
                <p className="description">
                  Enter the details of your donations to be recorded on the
                  blockchain for transparency and security.
                </p>
              </div>
              <div className="edit-add-body col-12">
                <h4 className="donationHeadSmall">Donation</h4>
                <ValidatorForm onSubmit={this.submitTransfer}>
                  <div>
                    <div>
                      {isPercentage == true ? (
                        <div className="row mx-0">
                          <p className="smallTxt">
                            Please seperate Amount in Percentage % with comma(,)
                            and don't put space in between.
                          </p>
                          <div className="col-12 col-md-6 pl-0">
                            <InputField
                              type="text"
                              name="startAmount"
                              value={startAmount}
                              onChange={this.handleEditChange}
                              label="START RANGE"
                              required={true}
                            />
                          </div>
                          <div className="col-12 col-md-6 pr-0">
                            <InputField
                              type="text"
                              name="endAmount"
                              value={endAmount}
                              onChange={this.handleEditChange}
                              label="END RANGE"
                              required={true}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="row mx-0">
                          <p className="smallTxt">
                            Please seperate Amount with comma(,) and don't put
                            space in between.
                          </p>
                          <div className="col-12 col-md-6 pl-0">
                            <InputField
                              type="text"
                              name="startAmount"
                              value={startAmount}
                              onChange={this.handleEditChange}
                              label="START RANGE"
                              required={true}
                              // helperText="Please seperate Amount with comma(,) and don't put space in between."
                            />
                          </div>
                          <div className="col-12 col-md-6 pr-0">
                            <InputField
                              fullWidth
                              type="text"
                              name="endAmount"
                              value={endAmount}
                              onChange={this.handleEditChange}
                              label="END RANGE"
                              required={true}
                              // helperText="Please seperate Amount with comma(,) and don't put space in between."
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className="col-12 col-md-6 px-2">
                      {isPercentage == true ? (
                        
                      ) : (
                        
                      )}
                    </div> */}
                  </div>
                  <div className="mt-3">
                    <p className="smallTxt">Please seperate Types with comma(,) and don't put space in between.</p>
                    <InputField
                      type="text"
                      name="type"
                      value={type}
                      onChange={this.handleEditChange}
                      label="DONATION TYPE"
                      required={true}
                    />
                  </div>
                  <div className="mt-3">
                  <p className="smallTxt">Please seperate Types with comma(,) and don't put space in between.</p>
                    <InputField
                      type="text"
                      name="address"
                      value={address}
                      onChange={this.handleEditChange}
                      label="BENEFICIARY ADDRESS"
                      required={true}
                    />
                  </div>
                  <div className="mt-3">
                  <p className="smallTxt">Please seperate Types with comma(,) and don't put space in between.</p>
                    <InputField
                      type="text"
                      name="tokenId"
                      value={tokenId}
                      onChange={this.handleEditChange}
                      label="TOKEN ID"
                      required={true}
                    />
                  </div>
                  <div className="edit-add-field mt-3 text-left">
                    Amount in Percentage % :
                    <Switch
                      onClick={this.togglePercentage}
                      label="Label"
                      checked={isPercentage}
                    />
                  </div>
                  <div className="edit-add-buttons mt-3">
                    <Button
                      className="submit-btn"
                      type="submit"
                      onSubmit={this.submitTransfer}
                    >
                      Add Donation Details
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader,
  getDonations,
};

const mapStateToProps = ({ Auth }) => {
  let { deployedDonations } = Auth;
  return { deployedDonations };
};
export default connect(mapStateToProps, mapDispatchToProps)(DonationsDetail);
