/*========== LOGIN ACTIONS ============= */
import {deployDonation} from "../sagas/Auth";

export const login = (data) => ({
      type: 'LOGIN',
      payload: data,
    });

export const setLoginData = (data) => ({
  type: 'SET_LOGIN_DATA',
  payload: data,
});

export const toggleLogin = () => ({
  type: 'TOGGLE_LOGIN',
});

export const isloginDisabled = () => ({
  type: 'IS_LOGIN_DISABLED',
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data
});

/*========== PAGE LOADER ACTIONS ============= */

export const setLoader = (data) => ({
  type: 'SET_LOADER',
  payload: data,
});


/*========== TRAANSACTION ACTIONS ============= */

export const getTransactionData = () => ({
  type: 'GET_TAX_DATA'
});

export const setTaxData = (data) => ({
  type: 'SET_TAX_DATA',
  payload: data
});

export const toggleInfoModal = (data) => ({
  type: 'TOGGLE_INFO_MODAL',
  payload: data
});

/*========== APPROVE KYC ACTIONS ============= */

export const updateKYC = (data) => ({
  type: 'UPDATE_KYC',
  payload: data,
});

export const getKYC = () => ({
  type: 'GET_KYC',
});

export const setKYC = (data) => ({
  type: 'SET_KYC',
  payload: data,
});

export const toggleStatusModal = (data) => ({
  type: 'TOGGLE_STATUS_MODAL',
  payload: data,
});

/*========== DEPLOY CONTRACT ACTIONS ============= */

export const deployDonations = (data) => (deployDonation({payload: data}),{
  type: 'DEPLOY_DONATIONS',
  payload: data,
});

export const getDonations = (data) => ({
  type: 'GET_DONATIONS',
  payload: data,
});

export const setDonations = (data) => ({
  type: 'SET_DONATIONS',
  payload: data,
});

export const getKycStatus = (data) => ({
  type: 'GET_KYC_STATUS',
  payload: data
});

export const setKycStatus = (data) => ({
  type: 'SET_KYC_STATUS',
  payload: data
});

export const toggleDeployModal = (data) => ({
  type: 'TOGGLE_DEPLOY_MODAL',
  payload: data,
});